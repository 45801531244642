import { template as template_d5a976a9c94a4294a0627e1bd9ad497b } from "@ember/template-compiler";
const FKLabel = template_d5a976a9c94a4294a0627e1bd9ad497b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
