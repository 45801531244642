import { template as template_e7de20cea9bb4382a26ded16720d5b3f } from "@ember/template-compiler";
const WelcomeHeader = template_e7de20cea9bb4382a26ded16720d5b3f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
